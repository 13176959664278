<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo mt-2 mt-md-0">
        <b-img
          src="@/assets/images/simiicons/Logo.svg"
          alt="logo"
        />
      </b-link>
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg p-2 p-lg-2"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <span
            class="heading1"
          >
            Adventure starts here🚀
          </span>
          <div class="subheading mt-3 mt-md-0">
            Make your Group With Other Members!
          </div>
          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- Group name -->
              <b-form-group
                label="Group Name"
                label-for="groupNameInput"
                class="label-class"
              >
                <b-skeleton
                  v-if="userDataLoader"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="groupNameInput"
                  plaintext
                  :value="groupName"
                />
              </b-form-group>
              <!-- Invited By -->
              <b-form-group
                label="Invited By"
                label-for="plainTextInput"
                class="label-class"
              >
                <b-skeleton
                  v-if="userDataLoader"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="plainTextInput"
                  plaintext
                  :value="invitedBy"
                />
              </b-form-group>
              <!-- email -->
              <b-form-group
                label="E-mail"
                label-for="account-e-mail"
                class="label-class"
              >
                <b-skeleton
                  v-if="userDataLoader"
                  type="input"
                />
                <b-form-input
                  v-else
                  v-model="user.email"
                  name="email"
                  placeholder="Email"
                  readonly
                />
              </b-form-group>
              <!-- name -->
              <span v-if="!hasUserName">
                <b-form-group
                  label="Name"
                  label-for="account-e-name"
                  class="label-class"
                >
                  <b-skeleton
                    v-if="userDataLoader"
                    type="input"
                  />
                  <b-form-input
                    v-else
                    v-model="payload.name"
                    name="name"
                    placeholder="Name "
                  />
                </b-form-group>
              </span>
              <!-- password -->
              <span v-if="!alreadyUser">
                <b-form-group
                  label-for="register-password"
                  label="Password"
                  class="label-class"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    rules="required|min:8"
                    vid="confirmation"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="payload.password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                  label-for="register-confirm-password"
                  label="Confirm Password"
                  class="label-class"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirm Password"
                    rules="required|confirmed:confirmation"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-confirm-password"
                        v-model="user.confirmPassword"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="register-confirm-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </span>
              <div class="d-flex justify-content-around align-items-center">
                <div class="form-group block">
                  <b-button
                    variant="success"
                    block
                    type="submit"
                    :disabled="acceptInvitationSpinner"
                    @click.prevent="acceptInvitation"
                  >
                    <div
                      v-if="acceptInvitationSpinner"
                      class="spinner"
                    >
                      <b-spinner
                        small
                      />
                      <span class="sr-only">Loading...</span>
                    </div>
                    Accept
                  </b-button>
                </div>
                <div class="form-group block">
                  <b-button
                    variant="primary"
                    block
                    type="submit"
                    :disabled="showModal"
                    @click.prevent="showConfirmRejectModal"
                  >
                    <div
                      v-if="spinner"
                      class="spinner"
                    >
                      <b-spinner
                        small
                      />
                      <span class="sr-only">Loading...</span>
                    </div>
                    Reject
                  </b-button>
                </div>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- modal -->
      <b-modal
        v-model="showModal"
        centered
        hide-footer
        hide-header
        :show-modal="false"
      >
        <div class="d-flex justify-content-center align-content-center align-items-center bg-white rounded">
          <b-row style="display: block">

            <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center">
              <img
                style="width: 80px"
                src="@/assets/images/simiicons/Success.svg"
              >
            </div>
            <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center m-2">
              <p class="font-weight-bolder heading text-primary">
                Are You Sure..?
              </p>
            </div>
            <div class="d-inline-block m-1 d-flex justify-content-center align-content-center align-items-center">
              <h4 style="font-size: 14px">
                You Want To Reject Group Invitation
              </h4>
            </div>
            <div class="d-flex justify-content-around align-items-center">
              <div
                class="form-group block"
              >
                <b-button
                  variant="outline-success"
                  type="submit"
                  :disabled="loader"
                  @click="continueFunc"
                >
                  <div
                    v-if="loader"
                    class="spinner"
                  >
                    <b-spinner
                      small
                    />
                    <span class="sr-only">Loading...</span>
                  </div>
                  Continue
                </b-button>
              </div>
              <div
                class="form-group block"
              >
                <b-button
                  variant="outline-warning"
                  block
                  type="submit"
                  @click="showModal = false"
                >
                  Cancel
                </b-button>
              </div>
            </div>
          </b-row>
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BSpinner,
  BSkeleton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import InvitationStatusType from '@/common/enums/invitationStatusEnum'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BSkeleton,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      InvitationStatusType,
      user: {
        email: '',
        confirmPassword: '',
      },
      payload: {
        name: '',
        invitation_status: 2,
        key: '',
        password: '',
      },
      hasUserName: false,
      alreadyUser: false,
      userDataLoader: false,
      groupName: 'email@pixinvent.comm',
      invitedBy: 't@testingSeptem.com',
      spinner: false,
      acceptInvitationSpinner: false,
      showModal: false,
      loader: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.payload.key = this.$route.query.key
    this.validateUserAndReceiveDetails()
  },
  methods: {
    passwordState() {
      return this.form.password.length >= 8
    },
    async validateUserAndReceiveDetails() {
      this.userDataLoader = true
      try {
        const res = await this.$axios
          .post('user-has-group/get-member', { key: this.payload.key })
        const { data: resData } = res || {}
        const { data } = resData || {}
        const { isPassword, memberDetails, isName } = data || {}
        this.hasUserName = isName
        this.alreadyUser = isPassword
        this.groupName = memberDetails.user_has_group.name
        this.invitedBy = memberDetails.invited_by.name
        this.user.email = memberDetails.user.email
        this.userDataLoader = false
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message.toString())
        await this.$router.push('/')
      }
    },
    async updateStatus() {
      if (!this.alreadyUser && this.payload.invitation_status === InvitationStatusType.APPROVED) {
        if (!this.payload.password) {
          this.$swal('Please enter your password!')
          return
        }
        if (!this.user.confirmPassword) {
          this.$swal('Confirm password not be empty!')
          return
        }
        if (this.payload.password !== this.user.confirmPassword) {
          showToast('Invitation Request', 'Confirm password not matched!', 'danger', 4000)
          return
        }
      }
      try {
        await this.$axios
          .put('user-has-group/update-invitation', this.payload)
        if (this.payload.invitation_status === InvitationStatusType.REJECTED) {
          this.showModal = true
        } else {
          await this.$router.push('/')
        }
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message.toString())
        if (message === 'Member\'s status has already been approved') {
          await this.$router.push('/')
        }
      }
    },
    async showConfirmRejectModal() {
      this.showModal = true
    },
    async acceptInvitation() {
      this.payload.invitation_status = InvitationStatusType.APPROVED
      this.acceptInvitationSpinner = true
      await this.updateStatus()
      this.acceptInvitationSpinner = false
    },
    async rejectInvitation() {
      this.payload.invitation_status = InvitationStatusType.REJECTED
      this.spinner = true
      await this.updateStatus()
      // this.showModal = true
      this.spinner = false
    },
    async continueFunc() {
      this.rejectInvitation()
      this.loader = true
      this.showModal = false
      await this.$router.push('/')
      this.loader = false
    },
  },
}
/* eslint-disable global-require */
</script>
<style scoped lang="scss">
@import "../src/@core/scss/vue/pages/page-auth";

.subheading{
  font-size: 14px;
  line-height: 30px;
  color: #3d3d3d;
  font-weight: 400;
  font-family: "Montserrat";
}
.heading1{
  width: 277px;
  height: 30px;
  font-size: 20px;
  line-height: 22px;
  color: #3d3d3d;
  font-weight: 500;
  font-family: "Montserrat";
}
.label-class{
  font-weight: 600 !important;
  font-size: 1rem !important;
}
</style>
